@import '../../../styles/var';

.streight {
    position: relative;
    // overflow: hidden;
    display: flex;
    margin-top: 160px;
    @media (max-width: $mw+px) {
        margin-top: 147px;
    }
    @media (max-width: $md3+px) {
        flex-direction: column-reverse;
        margin-top: 130px;
    }
    .image {
        z-index: 1;
        position: relative;
        width: 50%;
        @media (max-width: $md3+px) {
            width: 100%;
        }
        img {
            margin-left: -100px;
            margin-top: -100px;
            @media (max-width: $mw+px) {
                width: 766px;
            }
            @media (max-width: $md3+px) {
                margin: -100px auto 0 auto;
                display: flex;
            }
            @media (max-width: $md5+px) {
                width: 100%;
            }
            @media (max-width: $md6+px) {
                margin-top: -20px;
            }
        }
    }
    .content {
        width: 50%;
        position: relative;
        z-index: 2;
        @media (max-width: $md3+px) {
            width: 100%;
        }
        .header {
            font-family: 'TT Lakes Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 64px;
            line-height: 86px;
            @media (max-width: $mw+px) {
                font-size: 48px;
                line-height: 65px;
            }
            @media (max-width: $md6+px) {
                font-size: 36px;
                line-height: 49px;
            }
            z-index: 2;
            position: relative;
            span {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    background-color: #BD00FF;
                    z-index: -1;
                    height: 100%;
                    width: 221px;
                    right: 5px;
                    @media (max-width: $mw+px) {
                        width: 147px;
                    }
                    @media (max-width: $md6+px) {
                        width: 0;
                    }
                }
            }
        }
        .text {
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #BCBCBC;
            margin-top: 40px;
            @media (max-width: $mw+px) {
                font-size: 16px;
                line-height: 24px;
            }
            @media (max-width: $md6+px) {
                font-size: 14px;
                line-height: 21px;
            }
            .par {
                @media (max-width: $mw+px) {
                    margin-top: 15px;
                }
            }
        }
    }
}