@import "../../../styles/_var.scss";
.levels {
    overflow: visible;
    position: relative;
    z-index: 1;
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 200px;
        right: 120px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 100%;
        height: 50%;
        z-index: -1;
        transform: scale(2.5);
        background: url("../../../assets/img/affiliates/2/bg.png") center center / contain no-repeat;
    }

    margin-top: 120px;
    @media (max-width: $md5+px) {
        margin-top: 0px;
    }
}
.levelsBody {
    padding-top: 0px;
    padding-bottom: 180px;
    position: relative;
    z-index: 2;
    @media (max-width: $md5+px) {
        padding-top: 50px;
    }
}
.levelsTitle {
    max-width: 600px;
    margin-bottom: 60px;
}
.levelsRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md2+px) {
        width: 1352px;
        margin-bottom: 10px;
    }
    @media (max-width: $md3+px) {
        width: 1250px;
    }
    @media (max-width: $md5+px) {
        width: 100%;
        display: block;
    }
}
.levelsRowWrap {
    overflow: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        height: 5px;
        background-color: #44304b;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #bd00ff;
    }
}
.levelsRowColumn {
    width: 510px;
    height: 790px;
    padding: 220px 40px 40px 40px;
    transition: all .5s;
    &:hover {
        padding-top: 190px;
        @media(max-width: $md1+px) {
            padding-top: 160px;
        }
        @media(max-width: $md5+px) {
            padding-top: 300px;
        }
        @media (max-width: $md6+px) {
            padding-top: 176px;
        }
    }
    @media (max-width: $md1+px) {
        width: 430px;
        height: 600px;
        padding: 180px 40px 40px 40px;
    }
    @media(max-width: $md3+px) {
        height: 680px;
    }
    @media (max-width: $md5+px) {
        width: 100%;
        // height: 777px;
        padding: 300px 25px 28px 25px;
        margin-bottom: 40px;
    }
    @media (max-width: $md6+px) {
        padding: 176px 25px 40px 25px;
        height: auto;
    }
    &:nth-child(1) {
        background: url("../../../assets/img/About/card_01_1.png") 0 0 / cover no-repeat;
        @media (max-width: $md1+px) {
            background: url("../../../assets/img/About/card_01_2.png") 0 0 / cover no-repeat;
        }
        @media (max-width: $md5+px) {
            background: url("../../../assets/img/About/card_01_3.png") 0 0 / cover no-repeat;
        }
    }
    &:nth-child(2) {
        background: url("../../../assets/img/About/card_02_1.png") 0 0 / cover no-repeat;
        @media (max-width: $md1+px) {
            background: url("../../../assets/img/About/card_02_2.png") 0 0 / cover no-repeat;
        }
        @media (max-width: $md5+px) {
            background: url("../../../assets/img/About/card_02_3.png") 0 0 / cover no-repeat;
        }
    }
    &:nth-child(3) {
        background: url("../../../assets/img/About/card_03_1.png") 0 0 / cover no-repeat;
        @media (max-width: $md1+px) {
            background: url("../../../assets/img/About/card_03_2.png") 0 0 / cover no-repeat;
        }
        @media (max-width: $md5+px) {
            background: url("../../../assets/img/About/card_03_3.png") 0 0 / cover no-repeat;
        }
    }
}
.levelsRowColumnTitle {
    font-size: 32px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 20px;
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
}
.levelsRowColumnSubtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.4;
    color: #bcbcbc;
    @media (max-width: $md1+px) {
        font-size: 16px;
    }
}
