@import "../../../styles/_var.scss";

.onlineForexColContainer {
  position: relative;
  &:before {
    content: '';
    width: 205px;
    height: 105px;
    position: absolute;
    left: 0px;
    top: 55px;
    background-color: #BD00FF;
    z-index: 9;
    @media (max-width: $md3+px) {
      width: 80px;
      height: 69px;
      top: -17px;
    }
    @media (max-width: $md6+px) {
      display: none;
    }
  }
  .onlineForexColRow {
    display: flex;
    flex-wrap: wrap;
    .col5 {
      flex: 0 0 auto;
      width: 41.66666667%;
      @media (max-width: $md3+px) {
        margin-bottom: 100px;
      }
      @media (max-width: $md6+px) {
        display: none;
      }
      img {
        @media (max-width: $md3+px) {
          display: none;
        }
      }
    }
    .col7 {
      flex: 0 0 auto;
      width: 58.33333333%;
      @media (max-width: $md3+px) {
        width: 100%;
      }
      @media (max-width: $md6+px) {
        width: 100%;
      }
      &.onlineForexColWrap {
        margin-top: 100px;
        @media (max-width: $md3+px) {
          margin-top: 0;
        }
        @media (max-width: $md6+px) {
          margin-top: 0;
        }
        .onlineForexTitle {
          font-size: 60px;
          position: relative;
          z-index: 99;
          font-family: "TT Lakes Medium";
          line-height: 130%;
          @media (max-width: $md3+px) {
            font-size: 48px;
          }
          @media (max-width: $md6+px) {
            font-size: 36px;
          }
        }
        .onlineForexText {
          max-width: 665px;
          font-size: 20px;
          color: #BCBCBC;
          margin-top: 40px;
          line-height: 150%;
          @media (max-width: $md6+px) {
            font-size: 14px;
          }
        }
      }
      .dFlex {
        display: flex;
        align-items: flex-end;
        @media (max-width: $md3+px) {
          flex-direction: row;
        }
        @media (max-width: $md6+px) {
          flex-direction: column;
        }
        img {
          margin-left: 50px;
          @media (max-width: $md6+px) {
            margin: 40px 50px 0 0;
          }
        }
      }
    }
  }
}
