@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("../assets/fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "TT Lakes Medium";
    src: url("../assets/fonts/TTLakes-Medium.eot");
    src: url("../assets/fonts/TTLakes-Medium.eot?#iefix") format("embedded-opentype"),
        url("../assets/fonts/TTLakes-Medium.woff") format("woff"),
        url("../assets/fonts/TTLakes-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
