@import "../../../styles/_var.scss";
.currency {
    position: relative;
    overflow: visible;

    &:after {
        content: "";
        display: "inline-block";
        right: 700px;
        top: -200px;
        position: absolute;
        width: 802.87px;
        z-index: 2;
        height: 802.87px;
        pointer-events: none;
        background: url("../../../assets/img/would/2/bg.png") right 0 / contain no-repeat;
        transform: scale(3);
        @media (max-width: $md3+px) {
            top: 0;
        }
    }
}
.currencyBody {
    padding-bottom: 180px;
}
.currencyTitle {
    font-family: "TT Lakes Medium";
    font-size: 64px;
    line-height: 1.3;
    color: #ffffff;
    margin-bottom: 60px;
    text-align: center;
    @media (max-width: $md1+px) {
        font-size: 60px;
    }
    @media (max-width: $md2+px) {
        font-size: 55px;
    }
    @media (max-width: $md1+px) {
        font-size: 49px;
        margin-bottom: 55px;
    }
    @media (max-width: $md2+px) {
        font-size: 44px;
        margin-bottom: 50px;
    }
    @media (max-width: $md1+px) {
        font-size: 40px;
        margin-bottom: 45px;
    }
    @media (max-width: $md2+px) {
        font-size: 36px;
        margin-bottom: 40px;
    }
}
.currencyRowWrapper {
    overflow: auto;
}
.currencyRow {
    width: 1352px;
    height: 180px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    background: url("../../../assets/img/would/2/bg1.png") center 0 / contain no-repeat;
    display: flex;
    align-items: center;
    padding-left: 150px;
    @media (max-width: $md1+px) {
        width: 1300px;
        padding-left: 110px;
    }
    @media (max-width: $md2+px) {
        width: 1200px;
    }
    @media (max-width: $md3+px) {
        padding-left: 30px;
        width: 992px;
        background: url("../../../assets/img/would/2/bg1.png") 0 0 / cover no-repeat;
    }
}
.currencyRowColumn {
    flex: 0 1 auto;
    border-right: 1px solid #5b5b5b;
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;
    @media (max-width: $md3+px) {
        margin-right: 10px;
    }
    &:last-child {
        margin-right: 0;
    }
}
.currencyRowColumnName {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.08em;
    color: #ffffff;
    img {
        margin-right: 5px;
    }
}
.currencyRowColumnPrice {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: 0.08em;
    color: #bd00ff;
}

.currencyRowColumnPercent {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.1;
    letter-spacing: 0.08em;

    color: #58fff5;
}
