@import "../../../styles/_var.scss";

.aboutAffiliatesContainer {
  position: relative;
  margin-top: 180px;
  @media(max-width: $md6+px) {
    margin-top: 150px;
  }
  &:before {
    content: '';
    width: 205px;
    height: 105px;
    position: absolute;
    left: 0px;
    top: 75px;
    background-color: #BD00FF;
    z-index: 9;
    @media (max-width: $md3+px) {
      width: 80px;
      height: 80px;
      top: 85px;
    }
    @media (max-width: $md6+px) {
      display: none;
    }
  }
  .aboutAffiliatesRow {
    display: flex;
    flex-wrap: wrap;
    .col5 {
      flex: 0 0 auto;
      width: 41.66666667%;
      @media (max-width: $md3+px) {
        width: 100%;
      }
      @media (max-width: $md6+px) {
        width: 100%;
      }
      img {
        max-width: 100%;
        height: auto;
        @media (max-width: $md3+px) {
          display: block;
          margin: 0 auto;
        }
      }
    }
    .col7 {
      flex: 0 0 auto;
      width: 58.33333333%;
      @media (max-width: $md3+px) {
        width: 100%;
      }
      @media (max-width: $md6+px) {
        width: 100%;
      }
      &.aboutAffiliatesWrap {
        margin-top: 100px;
        .aboutAffiliatesTitle {
          font-size: 60px;
          position: relative;
          z-index: 99;
          display: flex;
          font-family: "TT Lakes Medium";
          line-height: 130%;
          @media (max-width: $md3+px) {
            font-size: 48px;
          }
          @media (max-width: $md6+px) {
            font-size: 36px;
          }
          img {
            position: absolute;
            right: 90px;
            top: -30px;
            z-index: -1;
            @media (max-width: $md3+px) {
              display: none;
            }
            @media (max-width: $md6+px) {
              display: none;
            }
          }
        }
        .aboutAffiliatesText {
          max-width: 665px;
          font-size: 20px;
          color: #BCBCBC;
          margin-top: 40px;
          line-height: 150%;
          @media (max-width: $md6+px) {
            font-size: 14px;
            margin-top: 10px;
          }
          @media (max-width: $md6+px) {
            font-size: 14px;
            margin-top: 10px;
          }
        }
        .aboutAffiliateList {
          margin-top: 40px;
          .aboutAffiliateItem {
            margin-bottom: 15px;
            display: flex;
            align-items: flex-start;
            p {
              font-size: 20px;
              line-height: 150%;
            }
            img {
              margin-right: 10px;
            }
          }
        }
      }
      .dFlex {
        display: flex;
        align-items: flex-end;
        img {
          margin-left: 50px;
        }
      }
    }
  }
}
