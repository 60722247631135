@import "../../../styles/_var.scss";
.footer {
    position: relative;
    z-index: 2;
    margin-top: 100px;
}
.footerBody {
    background: url("../../../assets/img/footer/bg.png") center 0 / cover no-repeat;
    @media (max-width: $md2+px) {
        background: url("../../../assets/img/footer/bg1.png") center 0 / cover no-repeat;
    }
    @media (max-width: $md4+px) {
        background: url("../../../assets/img/footer/bg2.png") center 0 / cover no-repeat;
    }
}
.footerRow {
    display: flex;
    flex-wrap: wrap;
    padding: 24px 0 48px 0;
    justify-content: space-between;
    @media (max-width: $md2+px) {
        padding: 24px 0;
    }
}

a {
    display: block;
    color: #fff;
    text-decoration: none;
}

.footerRowColumn,
.footerRowColumnForm,
.footerRowColumnCopyright {
    flex: 0 1 438px;
}
.footerRowColumn {
    &:nth-child(1) {
        margin-bottom: 33px;
        @media (max-width: $md1+px) {
            flex: 0 1 360px;
        }
        @media (max-width: $md2+px) {
            flex: 0 1 250px;
        }
        @media (max-width: $md3+px) {
            margin-bottom: 60px;
        }
    }
    &:nth-child(2) {
        @media (max-width: $md1+px) {
            flex: 0 1 400px;
        }
    }
    &:nth-child(3) {
        @media (max-width: $md1+px) {
            flex: 0 1 440px;
        }
    }
    &:nth-child(4) {
        @media (max-width: $md1+px) {
            flex: 0 1 400px;
        }
        @media (max-width: $md2+px) {
            flex: 0 1 280px;
        }
    }
    @media (max-width: $md3+px) {
        flex: 0 1 50% !important;
    }
}
.footerRowColumnLogo {
    margin-bottom: 21px;
    @media (max-width: $md3+px) {
        margin-bottom: 68px;
    }
    max-width: 70px;
    width: 70px;

    @media (max-width: $md6+px) {
        max-width: 60px;
        width: 60px;
    }
    img {
        width: 100%;
    }
}
.footerRowColumnItem,
.footerRowColumnItem a {
    font-size: 16px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 10px;
    @media (max-width: $md3+px) {
        margin-bottom: 30px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.footerRowColumnForm {
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
        margin: 71px 0 61px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.footerRowColumnFormTitle {
    margin-bottom: 10px;
    @media (max-width: $md3+px) {
        position: relative;
        top: 21px;
    }
    @media (max-width: $md5+px) {
        width: 100%;
        top: 0;
        margin-bottom: 20px;
    }
}
.footerRowColumnFormInputs {
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    @media (max-width: $md5+px) {
        width: 100%;
        flex-wrap: wrap;
    }
    input {
        width: 300px;
        height: 47px;
        left: 697px;
        top: 257px;
        background-color: transparent;
        border: 1px solid #bcbcbc;
        font-size: 16px;
        color: #fff;
        padding: 0 15px;
        margin-right: 21px;
        &::placeholder {
            color: #676767;
        }
        @media (max-width: $md3+px) {
            width: 374px;
        }
        @media (max-width: $md4+px) {
            width: 300px;
        }
        @media (max-width: $md5+px) {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
        }
    }
    button {
        width: 116px;
        height: 47px;
        background: #bd00ff;
        text-align: center;
        line-height: 47px;
        font-size: 16px;

        color: #ffffff;
        @media (max-width: $md5+px) {
            width: 100%;
        }
        cursor: pointer;
        &:hover {
            &::before {
                width: 100%;
            }
        }
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: rgba(255, 255, 255, 0.3);
            transition: all 0.5s ease;
        }
    }
}
.footerRowColumnFormPays {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.footerRowColumnCopyright {
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.footerRowColumnCopyrightTitle {
    font-weight: 700;
    font-size: 10px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 8px;
}
.footerRowColumnCopyrightSubtitle {
    font-size: 10px;
    line-height: 1.4;
    color: #a5a5a5;
    margin-bottom: 20px;
}
.footerRowColumnCopyrightText {
    font-size: 16px;
    line-height: 1.2;
    color: #ffffff;

    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    @media (max-width: 389px) {
        font-size: 13px;
    }
}
