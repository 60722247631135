@import "../../../styles/_var.scss";

.benefitWrap {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(50% 50% at 50% 50%, #BD00FF 0%, #7900A4 49.66%, #2004E9 100%);
    opacity: 0.5;
    filter: blur(250px);
    width: 400px;
    height: 400px;
    z-index: 9;
  }
  &:before {
    content: '';
    width: 220px;
    height: 105px;
    position: absolute;
    left: 0;
    top: -35px;
    background-color: #BD00FF;
    z-index: 9;
    @media (max-width: $md3+px) {
      width: 147px;
      height: 69px;
      top: -20px;
    }
    @media (max-width: $md6+px) {
      display: none;
    }
  }
  .benefitTitle {
    font-size: 64px;
    position: relative;
    z-index: 999;
    font-family: "TT Lakes Medium";
    line-height: 130%;
    @media (max-width: $md3+px) {
      font-size: 48px;
    }
    @media (max-width: $md6+px) {
      font-size: 36px;
    }
    span {
      display: block;
    }
  }
  .benefitRowWrap {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .benefitRow {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 40px;
    position: relative;
    z-index: 9999;
    @media (max-width: $md2+px) {
      width: 1352px;
      margin-bottom: 10px;
    }
    @media (max-width: $md3+px) {
      width: 1250px;
    }
    @media (max-width: $md5+px) {
      width: 920px;
    }
    @media (max-width: $md6+px) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .col {
      flex: 1 0 0%;
      width: 100%;
      max-width: 100%;
      margin-bottom: 25px;
      transition: all .3s ease-in-out;
      @media (max-width: $md6+px) {
        margin-bottom: 0;
        width: 294px;
        height: 144px;
      }
      .benefitItem {
        background-image: url("../../../assets/img/Index/benefit-item-bg.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        padding: 0 90px;
        height: 190px;
        display: flex;
        align-items: center;
        p {
          padding: 60px 0;
          line-height: 24px;
          transition: all .3s;
          &:hover {
            transform: translateY(-10px);
          }
        }
        @media (max-width: $md3+px) {
          background-image: url("../../../assets/img/Index/benefit-item-mobile-bg.png");
          padding: 45px;
        }
        @media (max-width: $md6+px) {
          background-image: url("../../../assets/img/Index/benefit-item-mobile-bg.png");
          padding: 15px;
        }
        p {
          font-size: 21px;
          color: #fff;
          @media (max-width: $md3+px) {
            font-size: 16px;
            padding: 45px;
          }
          @media (max-width: $md6+px) {
            font-size: 16px;
            padding: 15px;
          }
        }
      }
    }
  }
  .benefitBottomText {
    font-size: 24px;
    max-width: 960px;
    line-height: 150%;
    @media (max-width: $md3+px) {
      font-size: 20px;
    }
    @media (max-width: $md6+px) {
      font-size: 16px;
      margin-top: 30px;
    }
  }
}
