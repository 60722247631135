@import "../../../styles/_var.scss";
.hit {
}
.hitBody {
    background: url("../../../assets/img/affiliates/4/1.png") center 0 / cover no-repeat;
    margin-bottom: 200px;
    padding: 60px 0 80px 0;
    @media (max-width: $md1+px) {
        padding: 60px 0 57px 0;
    }
    @media (max-width: $md5+px) {
        margin: 0 -10px 200px -10px;
    }
}
.hitTitle {
    font-family: "TT Lakes Medium";
    font-size: 64px;
    line-height: 1.4;
    text-align: center;
    color: #ffffff;

    margin: 0 auto 20px auto;
    span {
        display: block;
    }
    @media (max-width: $md1+px) {
        font-size: 48px;
        margin: 0 auto 10px auto;
    }
    @media (max-width: $md4+px) {
        font-size: 42px;
    }
    @media (max-width: $md5+px) {
        font-size: 40px;
    }
    @media (max-width: $md6+px) {
        font-size: 36px;
    }
}
.hitSubtitle {
    font-size: 24px;
    line-height: 1.2;
    text-align: center;
    color: #bcbcbc;
    margin-bottom: 40px;
    @media (max-width: $md1+px) {
        font-size: 16px;
        margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
        max-width: 331px;
        margin: 0 auto 30px auto;
    }
}
.hitBtn {
    width: 250px;
    height: 52px;
    margin: 0 auto;
    background: #bd00ff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    span {
        font-weight: 500;
        font-size: 20px;
        &:nth-child(2) {
            margin: 0 10px;
        }
    }
    @media (max-width: $md5+px) {
        width: 179px;
        height: 49px;
    }
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        transition: all 0.5s ease;
    }
    &:hover {
        &::before {
            width: 100%;
        }
    }
}
