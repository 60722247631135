@import "../../../styles/_var.scss";
.trade {
}
.tradeBody {
    margin-bottom: 180px;
    position: relative;
    z-index: 2;
}
.tradeRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        display: block;
    }
}
.tradeRowLeft {
    flex: 0 1 813px;
    @media (max-width: $md1+px) {
        flex: 0 1 700px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md3+px) {
        margin-bottom: 50px;
    }
}
.tradeRowLeftTitle {
    margin-bottom: 40px;
}
.tradeRowLeftSubtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.3;
    color: #bcbcbc;
}
.tradeRowRight {
    flex: 0 1 525px;
}
.tradeRowRightForm {
    display: flex;
    flex-direction: column;
}
.tradeRowRightFormTitle {
    font-family: "TT Lakes Medium";
    font-size: 48px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 20px;
}
.tradeRowRightFormInput {
    margin-bottom: 15px;
    input {
        background-color: #000;
        font-size: 16px;
        height: 54px;
        width: 100%;
        line-height: 54px;
        color: #fff;
        padding: 0 15px;
        box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.25);
        &::placeholder {
            color: #515151;
        }
        position: relative;
        z-index: 2;
    }
    position: relative;
    &:after {
        content: "";
        display: "inline-block";
        left: -1px;
        top: -1px;
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: linear-gradient(90deg, #5e0d7b, #000);
        z-index: 1;
    }
}
.tradeRowRightFormSend {
    align-self: flex-end;
    width: 133px;
    height: 52px;
    background: #bd00ff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    img {
        margin-left: 10px;
    }
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover {
        &::before {
            width: 100%;
        }
    }
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        transition: all 0.5s ease;
    }
}
