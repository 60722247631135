@import "../../../styles/_var.scss";
.pack {
}
.packBody {
    margin-bottom: 200px;
    @media (max-width: $md5+px) {
        margin-bottom: 120px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 90px;
    }
}
.packTitle {
    margin-bottom: 40px;
    @media (max-width: $md5+px) {
        text-align: center;
    }
}

.packSubtitle {
    font-size: 20px;
    line-height: 1.4;
    color: #bcbcbc;
    margin-bottom: 15px;
    @media (max-width: $md5+px) {
        display: none;
    }
}
.packRow {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    @media (max-width: $md5+px) {
        flex-wrap: wrap;
    }
}
.packRowColumn {
    flex: 0 1 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: $md5+px) {
        flex: 0 1 100%;
        margin-bottom: 44px;
    }
    &:nth-child(1) {
        .packRowColumnImg img {
            height: 82px;
        }
    }
    &:nth-child(2) {
        .packRowColumnImg img {
            height: 80px;
        }
    }
    &:nth-child(3) {
        .packRowColumnImg img {
            height: 80px;
        }
    }
    &:nth-child(4) {
        .packRowColumnImg img {
            height: 88.5px;
        }
    }
    &:nth-child(5) {
        .packRowColumnImg img {
            height: 88.5px;
        }
    }
}
.packRowColumnImg {
    height: 88.5px;
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.packRowColumnSubtitle {
}
