@import "../../../styles/_var.scss";
.first {
    padding: 150px 0 320px 0;
    @media (max-width: $md4+px) {
        padding: 150px 0 350px 0;
    }

    @media (max-width: $md5+px) {
        padding: 100px 0 350px 0;
    }
    @media (max-width: $md6+px) {
        padding: 50px 0 350px 0;
    }
}

.firstBody {
    position: relative;
    &:after {
        content: "";
        display: "inline-block";
        right: -120px;
        top: -140px;
        position: absolute;
        width: 900px;
        height: 900px;
        background: url("../../../assets/img/affiliates/1/1.png") 0 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            width: 800px;
            height: 800px;
        }
        @media (max-width: $md2+px) {
            top: -170px;
        }
        @media (max-width: $md3+px) {
            width: 600px;
            height: 600px;
            top: -25px;
        }
        @media (max-width: $md4+px) {
            width: 600px;
            height: 600px;
            top: 240px;
            left: 0;
            right: 0;
            margin: auto;
        }
        @media (max-width: 630px) {
            width: 390px;
            height: 490px;
            top: 400px;

            transform: scale(1.5);
        }

        @media (max-width: 500px) {
            width: 390px;
            height: 490px;
            top: 450px;

            transform: scale(1.5);
        }
        @media (max-width: 420px) {
            width: 370px;
            height: 470px;
            top: 490px;
            right: 0;
            transform: scale(1.5);
        }
    }
}
.firstMobImg {
    display: none;
}
.firstTitle {
    max-width: 800px;
    margin-bottom: 40px;
    @media (max-width: $md1+px) {
        max-width: 600px;
    }
}
.firstSubtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.3;
    color: #bcbcbc;
    max-width: 800px;
    @media (max-width: $md1+px) {
        font-size: 16px;
        max-width: 552px;
    }
    @media (max-width: $md4+px) {
        font-size: 14px;
        max-width: 100%;
    }
}
