@import "../../../styles/_var.scss";
.trade {
}
.tradeBody {
}
.tradeRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        margin-bottom: 40px;
    }
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}
.tradeRowLeft {
    flex: 0 1 617px;
    img {
        width: 100%;
    }
    @media (max-width: $md4+px) {
        order: 2;
        position: relative;
        right: -13%;
    }
}
.tradeRowRight {
    flex: 0 1 813px;
    padding-top: 100px;
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
    }
}
.tradeRowRightTitle {
    margin-bottom: 40px;
}
.tradeRowRightSubtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.5;
    color: #bcbcbc;
}
