@import '../../../styles/var';

.wrapper {
    max-width: 1620px;
    margin: 0 auto;
}

.banner {
    position: relative;
    margin-top: 20px;
    &Img {
        height: 100%;
        position: absolute;
        right: 0;
        z-index: 1;
        @media (max-width: $md6+px) {
            right: auto;
            bottom: 0;
            height: auto;
            width: 100%;
        }
    }
    .gs {
        position: absolute;
        z-index: 3;
        cursor: pointer;
        bottom: 55px;
        left: 35%;
        @media (max-width: $md3+px) {
            left: 80%;
        }
        @media (max-width: $md6+px) {
            left: auto;
            right: 50px;
            bottom: 253px;
        }
    }
    &Content {
        padding-left: 80px;
        padding-right: 80px;
        position: relative;
        z-index: 2;
        background: linear-gradient(90deg, #330C4F 63.41%, rgba(27, 27, 27, 0) 70.33%);
        @media (max-width: $md3+px) {
            background: linear-gradient(90deg, #330C4F 63.41%, rgba(27, 27, 27, 0) 100.33%);
        }
        @media (max-width: $md6+px) {
            padding: 0 10px;
            background: none;
            background: linear-gradient(180deg, black 63.41%, rgba(27, 27, 27, 0) 80.33%);
        }
        .header {
            font-family: "TT Lakes Medium";
            font-weight: 500;
            font-size: 96px;
            line-height: 130px;
            padding-top: 100px;
            @media (max-width: $mw+px) {
                font-size: 64px;
                line-height: 86px;
                padding-top: 136px;
            }
            @media (max-width: $md6+px) {
                font-size: 48px;
                line-height: 68px;
                padding-top: 50px;
            }
        }
        .text {
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: #BCBCBC;
            width: 552px;
            margin: 20px 0 40px 0;
            @media (max-width: $md5+px) {
                width: 100%;
            }
            @media (max-width: $md6+px) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 246px;
            }
        }
        .buttons {
            display: flex;
            gap: 20px;
            padding-bottom: 130px;
            @media (max-width: $md6+px) {
                display: block;
            }
            @media (max-width: $md6+px) {
                padding-bottom: 100px;
            }
            a:last-child {
                @media (max-width: $md6+px) {
                    margin-top: 20px;
                }
            }
        }
    }
}