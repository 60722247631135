@import "../../../styles/_var.scss";

.dMd6None {
  display: flex;
  @media (max-width: $md6+px) {
    display: none;
  }
}

.dFlex {
  display: none;
  @media (max-width: $md6+px) {
    display: flex;
  }
}

.bannerWrap {
  padding: 160px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $md6+px) {
    padding: 95px 0 40px 0;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(50% 50% at 50% 50%, #bd00ff 0%, #7900a4 49.66%, #7900a4 100%);
    opacity: 0.5;
    filter: blur(140px);
    width: 300px;
    height: 300px;
  }
}

.bannerImage,
.bannerInfo {
  flex: 0 0 auto;
  width: 50%;
  @media (max-width: $md3+px) {
    flex: 0 0 auto;
    width: 100%;
  }
  @media (max-width: $md6+px) {
    flex: 0 0 auto;
    width: 100%;
  }
}

.bannerTitle {
  font-size: 96px;
  position: relative;
  margin-bottom: 40px;
  font-family: "TT Lakes Medium";
  @media (max-width: $md3+px) {
    font-size: 64px;
    max-width: 660px;
  }

  @media (max-width: $md6+px) {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 20px;
  }
}

.bannerText {
  font-size: 20px;
  color: #bcbcbc;
  max-width: 660px;
  line-height: 150%;
  
  @media (max-width: $md6+px) {
    font-size: 14px;
  }
  @media (max-width: $md3+px) {
    font-size: 16px;
  }
}

.bannerList {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
  @media (max-width: $md6+px) {
    flex-direction: column;
  }
}

.bannerItem {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 50%;
  padding-bottom: 20px;
  color: #fff;
  z-index: 99;
  font-size: 16px;
  line-height: 150%;
  @media (max-width: $md3+px) {
    width: 35%;
  }
  @media (max-width: $md6+px) {
    margin-bottom: 10px;
    padding-bottom: 0;
    flex: 0 0 auto;
    width: 100%;
    p {
      font-size: 16px;
      font-weight: 600;
    }
  }
  &:first-child,
  &:nth-child(2) {
    margin-bottom: 20px;
    @media (max-width: $md6+px) {
      margin-bottom: 10px;
    }
  }
  img {
    padding-right: 10px;
  }
}

.bannerButtonWrap {
  gap: 20px;
  @media (max-width: $md6+px) {
    flex-direction: column;
    position: absolute;
    width: calc(100% - 20px);
    bottom: 80px;
    z-index: 999;
    justify-content: center;

    margin: 0 10px;
  }
  .btn-default {
    &.lastBtn {
      margin-left: 40px;
    }
  }
}

.getStartedWrap {
  position: relative;
  @media (max-width: $md3+px) {
    width: 70%;
  }
  @media (max-width: $md4+px) {
    right: -100px;
  }
  @media (max-width: 630px) {
    right: -150px;
  }
  @media (max-width: 550px) {
    right: -190px;
    transform: scale(0.8);
  }
  @media (max-width: $md6+px) {
    position: absolute;
    top: 120px;
    right: 0px;
    transform: scale(1);
    width: 100%;
    z-index: 99;
    display: none;
  }
  img {
    position: absolute;
    right: 50px;
    top: -100px;
    @media (max-width: $md3+px) {
      top: -80px;
    }
  }
}

.bannerImage {
  display: block;
  .imagesWrap {
    position: relative;
    width: fit-content;
    @media (max-width: $md3+px) {
      margin: 100px auto;
    }
    &.shadowImg {
      &:before {
        content: "";
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 60%);

        z-index: 9;
        @media (max-width: $md6+px) {
          display: block;
        }
      }
    }

    .hideImgElem {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      width: fit-content;
      @media (max-width: $md5+px) {
        display: none;
      }
      .hoverBlock {
        font-size: 38px;
        font-weight: 100;
        width: 98px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #1b1b1b;
        color: #bd00ff;
        transition: all 3s;
        opacity: 1;
        &:hover {
          opacity: 0;
          transition: none;
        }
        @media (max-width: 1919px) {
          width: 114.3px;
          height: 100px;
        }
        @media (max-width: 1680px) {
          width: 114.3px;
          height: 86px;
        }
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
