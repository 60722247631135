@import "../../../styles/_var.scss";

.aboutUsWrap {
  padding: 120px 60px;
  background-image: url("../../../assets/img/Index/about-us-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 175px;
  @media (max-width: $md3+px) {
    padding: 50px 20px 170px;
    background-image: url("../../../assets/img/Index/about-us-mobile-bg.png");
  }
  @media (max-width: $md6+px) {
    padding: 50px 20px 170px;
    background-image: url("../../../assets/img/Index/about-us-mobile-bg.png");
  }
  .aboutUsTitle {
    font-size: 60px;
    margin-bottom: 40px;
    font-family: "TT Lakes Medium";
    line-height: 130%;
    @media (max-width: $md6+px) {
      font-size: 36px;
    }
  }

  .aboutUsText {
    font-size: 20px;
    color: #BCBCBC;
    max-width: 740px;
    width: 100%;
    margin-bottom: 20px;
    line-height: 150%;
    @media (max-width: $md6+px) {
      font-size: 14px;
    }
  }

  .aboutUsTextWhite {
    font-size: 24px;
    max-width: 740px;
    width: 100%;
    line-height: 150%;
    @media (max-width: $md6+px) {
      font-size: 16px;
    }
  }
}
