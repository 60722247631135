@import '../../../styles/var';

.startNow {
    background-image: url('../../../assets/img/Tools/start_now.png');
    background-size: cover;
    background-position: center;
    width: 1620px;
    margin: 180px auto 0 auto;
    @media (max-width: $mw+px) {
        width: 100%;
        margin: 150px 0 0 0;
    }
    .header{
        text-align: center;
        font-family: 'TT Lakes Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 64px;
        line-height: 86px;
        padding-top: 50px;
        @media (max-width: $mw+px) {
            font-size: 36px;
            line-height: 49px;
            padding-top: 86px;
        }
    }
    .text {
        text-align: center;
        margin: 10px 0 40px 0;
        font-weight: 300;
        font-size: 24px;
        line-height: 24px;
        color: #BCBCBC;
        @media (max-width: $mw+px) {
            font-size: 16px;
            line-height: 24px;
            margin: 15px 0 40px 0;
        }
    }
    .btn {
        text-align: center;
        margin-left: auto;
        padding-bottom: 86px;
        a {
            color: #fff;
        }
    }
}