@import "../../../styles/_var.scss";

.tradeBenefitRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 200px;
  @media (max-width: $md3+px) {
    margin-top: 100px;
    flex-direction: column-reverse;
  }
  @media (max-width: $md6+px) {
    margin-top: 0;
    flex-direction: column-reverse;
  }
  .col5 {
    flex: 0 0 auto;
    width: 41.66666667%;
    @media (max-width: $md3+px) {
      margin: 0 auto;
    }
    @media (max-width: $md6+px) {
      width: 100%;
    }
  }
  .col7 {
    flex: 0 0 auto;
    width: 58.33333333%;
    @media (max-width: $md6+px) {
      width: 100%;
    }
    &.tradeBenefitColWrap {
      margin-top: 150px;
      .tradeBenefitTitle {
        position: relative;
        font-family: "TT Lakes Medium";
        line-height: 130%;
        z-index: 99;
        font-size: 64px;
        @media (max-width: $md3+px) {
          font-size: 48px;
        }
        @media (max-width: $md6+px) {
          font-size: 36px;
        }
        &:before {
          content: '';
          width: 220px;
          height: 105px;
          position: absolute;
          left: -125px;
          top: -15px;
          background-color: #BD00FF;
          z-index: -1;
          @media (max-width: $md3+px) {
            width: 95px;
            height: 69px;
            top: -11px;
            left: -20px;
          }
          @media (max-width: $md6+px) {
            display: none;
          }
        }
      }
      .tradeBenefitText {
        font-size: 20px;
        color: #BCBCBC;
        margin-top: 40px;
        line-height: 150%;
        @media (max-width: $md3+px) {
          font-size: 16px;
        }
        @media (max-width: $md6+px) {
          font-size: 14px;
        }
      }
      .tradeBenefitTextWhite {
        font-size: 24px;
        line-height: 150%;
        margin-top: 20px;
        @media (max-width: $md3+px) {
          font-size: 20px;
        }
        @media (max-width: $md6+px) {
          font-size: 16px;
        }
      }
    }
  }
}
