@import "../../../styles/_var.scss";
.become {
}
.becomeBody {
    padding-bottom: 114px;
    padding-top: 60px;
    background: url("../../../assets/img/affiliates/6/1.png") 430px 0 no-repeat,
        url("../../../assets/img/affiliates/6/2.png") 800px 200px no-repeat;
    @media (max-width: $md4+px) {
        background: unset;
    }
    @media (max-width: $md5+px) {
        padding-bottom: 60px;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 30px;
    }
}
.becomeTitle {
    margin-bottom: 40px;
}

.becomeSubtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.5;
    color: #bcbcbc;
    max-width: 800px;
}
