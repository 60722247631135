@import "../../../styles/_var.scss";
.popular {
    position: relative;
    z-index: 40;
}
.popularBody {
}
.popularTitle {
    max-width: 1100px;
    margin-bottom: 34px;
    @media (max-width: $md4+px) {
        span {
            max-width: 100% !important;
        }
        max-width: 100%;
    }
}
.popularSpoiler {
    max-width: 780px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
    &:after {
        content: "";
        display: "inline-block";
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 1124px;
        height: 1px;
        display: block;
        background: linear-gradient(90deg, #1b1b1b 0%, #bc01fe 50%, #1b1b1b 100%);
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        @media (max-width: $md4+px) {
            width: 900px;
        }
        @media (max-width: 830px) {
            background: linear-gradient(90deg, #1b1b1b 10%, #bc01fe 50%, #1b1b1b 90%);
        }
        @media (max-width: 630px) {
            background: linear-gradient(90deg, #1b1b1b 20%, #bc01fe 50%, #1b1b1b 80%);
        }
        @media (max-width: 530px) {
            background: linear-gradient(90deg, #1b1b1b 30%, #bc01fe 50%, #1b1b1b 70%);
        }
    }
}
.popularSpoilerTitle {
    height: 90px;
    font-size: 24px;
    line-height: 1;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    @media (max-width: $md4+px) {
        font-size: 22px;
        padding-right: 200px;
    }
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
    @media (max-width: 530px) {
        padding-right: 150px;
    }
    @media (max-width: 450px) {
        padding-right: 100px;
    }
    @media (max-width: 400px) {
        padding-right: 70px;
    }
    &:after {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 32px;
        height: 1px;
        background-color: #fff;
    }
    &:before {
        content: "";
        display: "inline-block";
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 1px;
        height: 32px;
        background-color: #fff;
    }
    &.active {
        &:before {
            transform: rotate(90deg);
        }
    }
}
.popularSpoilerContent {
    font-weight: 300;
    font-size: 16px;
    line-height: 1.4;
    max-width: 666px;
    color: #bcbcbc;
    align-self: flex-end;
    position: relative;
    margin-bottom: 15px;
    display: none;
    &:hover {
        &:after {
            transform: scale(1.5);
        }
    }
    &.active {
        display: block;
    }
    &:after {
        content: "";
        display: "inline-block";
        left: -30px;
        top: 3px;
        position: absolute;
        width: 20px;
        height: 20px;
        background: url("../../../assets/img/affiliates/3/Star.svg") 0 0 no-repeat;
        transition: all 0.2s linear;
        display: none;
    }
    &.star {
        &:after {
            display: block;
        }
    }
}

.popularCommission {
    margin-top: 80px;
    position: relative;
    margin-bottom: 150px;
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        right: 0;
        margin: auto;
        top: -50px;
        position: absolute;
        width: 378.57px;
        height: 378.57px;
        background: url("../../../assets/img/affiliates/3/bg.png") 0 0 / cover no-repeat;
        transform: scale(3);
        pointer-events: none;
    }
}
.popularCommissionTitle {
    font-size: 36px;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    margin-bottom: 43px;
    @media (max-width: $md5+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}
.popularCommissionRowWrapper {
    position: relative;
    z-index: 4;
    &:nth-child(odd) {
        background-color: #1b1b1b;
        @media (max-width: $md3+px) {
            margin: 0 -20px;
        }
    }
}
.popularCommissionRow {
    width: 316px;
    height: 51px;
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
}
.popularCommissionRowName {
    font-weight: 300;
    font-size: 20px;
    line-height: 1;
    color: #bcbcbc;
    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}
.popularCommissionRowPrice {
    font-weight: 300;
    font-size: 20px;
    line-height: 1;
    color: #fff;
    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}
