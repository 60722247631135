@import '../../../styles/var';

.streight {
    position: relative;
    display: flex;
    margin-top: 200px;
    @media (max-width: $mw+px) {
        margin-top: 150px;
    }
    @media (max-width: $md3+px) {
        display: block;
    }
    @media (max-width: $md3+px) {
        margin-bottom: 150px;
    }
    .image {
        z-index: 1;
        position: relative;
        width: 50%;
        @media (max-width: $md3+px) {
            width: 100%;
        }
        img {
            margin-top: -50px;
            margin-left: auto;
            display: flex;
            @media (max-width: $mw+px) {
                width: 673px;
                margin-right: -150px;
                margin-top: -130px;
            }
            @media (max-width: $md3+px) {
                display: none;
            }
        }
    }
    .content {
        width: 50%;
        position: relative;
        z-index: 2;
        @media (max-width: $md3+px) {
            width: 100%;
        }
        .header {
            font-family: 'TT Lakes Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 64px;
            line-height: 86px;
            @media (max-width: $mw+px) {
                font-size: 48px;
                line-height: 65px;
            }
            @media (max-width: $md6+px) {
                font-size: 36px;
                line-height: 49px;
            }
            z-index: 2;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                background-color: #BD00FF;
                z-index: -1;
                height: 104px;
                top: -7px;
                width: 221px;
                left: -151px;
                @media (max-width: $mw+px) {
                    // width: 147px;
                    height: 69px;
                    top: -5px;
                }
                @media (max-width: $md4+px) {
                    display: none;
                }
            }
        }
        .text {
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #BCBCBC;
            margin-top: 40px;
            @media (max-width: $mw+px) {
                font-size: 16px;
                line-height: 24px;
            }
            @media (max-width: $md6+px) {
                font-size: 14px;
                line-height: 21px;
            }
            .par {
                margin-top: 15px;
            }
        }
    }
}