@import "../../../styles/_var.scss";

.would {
}
.wouldBody {
    padding-top: 120px;
    padding-bottom: 30px;
}
.wouldRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}
.wouldRowLeft {
    flex: 0 1 50%;
    @media (max-width: $md2+px) {
        flex: 0 1 60%;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 50%;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
    }
}
.wouldTitle {
    margin-bottom: 40px;
}

.wouldSubtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.4;
    color: #bcbcbc;
    margin-bottom: 20px;
}

.wouldText {
    font-family: "TT Lakes Medium";
    font-weight: 500;
    font-size: 24px;
    line-height: 1.4;
    color: #ffffff;
}
.wouldRowRight {
    position: relative;
    top: -100px;
    @media (max-width: $md2+px) {
        flex: 0 1 40%;
        top: 0;
        img {
            width: 100%;
        }
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
    }
}
