@import '../../../styles/var';

.advantages {
    margin-top: 200px;
    @media (max-width: $mw+px) {
        margin-top: 180px;
    }
    @media (max-width: $md6+px) {
        margin-top: 150px;
    }
    .header {
        @media (max-width: $md4+px) {
            span {
                max-width: 100%!important;
            }
        }
    }
    .content {
        margin-top: 60px;
        display: grid;
        grid-template: repeat(2,1fr)/repeat(4,1fr);
        gap: 40px;
        cursor: pointer;
        @media (max-width: $mw+px) {
            margin-top: 40px;
        }
        @media (max-width: $md3+px) {
            grid-template: repeat(4,1fr)/repeat(2,1fr);
            padding: 0px 0px;
        }
        @media (max-width: $md6+px) {
            display: block;
        }
        .item {
            position: relative;
            @media (max-width: $md6+px) {
                display: flex;
                margin-bottom: 20px;
            }
            &:hover img {
                scale: 1.5;
            }
            img {
                transition: all 0.2s;
                @media (max-width: $md6+px) {
                    width: 20px;
                    height: 20px;
                }
            }
            .text {
                margin-top: 10px;
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                @media (max-width: $mw+px) {
                    font-size: 16px;
                    line-height: 24px;
                }
                @media (max-width: $mw+px) {
                    margin-top: 0;
                    margin-left: 20px;
                }
            }
        }
    }
}