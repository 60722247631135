// @import "parts/titles.module.scss";
// @import "parts/buttons.module.scss";
// @import "parts/headerSlider.module.scss";
// @import "parts/collectionsColumn.module.scss";
// @import "parts/baner.module.scss";
// @import "parts/otherPageColumn.module.scss";
@import "./_var.scss";
body,
html {
    background-color: #1b1b1b;
    color: #fff;
    overflow-x: hidden;
}
.bigTitle {
    position: relative;
    span {
        position: relative;
        z-index: 2;
        font-family: "TT Lakes Medium";
        font-size: 64px;
        line-height: 1.4;
        color: #ffffff;
        @media (max-width: $md1+px) {
            display: block;
        }

        @media (max-width: $md4+px) {
            max-width: 340px;
            font-size: 48px;
        }
    }
    &:after {
        content: "";
        display: "inline-block";
        left: -150px;
        top: -7px;
        z-index: 1;
        position: absolute;
        width: 221px;
        height: 104px;
        background: #bd00ff;
        @media (max-width: $md4+px) {
            display: none;
        }
        @media (max-width: $md1+px) {
            height: 70px;
            top: 9px;
        }
    }
}
.simpleTitle {
    position: relative;
    span {
        position: relative;
        z-index: 2;
        font-family: "TT Lakes Medium";
        font-size: 64px;
        line-height: 1.4;
        color: #ffffff;
        @media (max-width: $md1+px) {
            font-size: 48px;
            display: block;
        }

        @media (max-width: $md4+px) {
            max-width: 100%;
            font-size: 36px;
        }
    }
    &:after {
        content: "";
        display: "inline-block";
        left: -150px;
        top: -7px;
        z-index: 1;
        position: absolute;
        width: 221px;
        height: 104px;
        background: #bd00ff;
        @media (max-width: $md1+px) {
            height: 70px;
            top: -2px;
        }
        @media (max-width: $md4+px) {
            display: none;
        }
    }
}

.btn-default {
    border: 1px solid #bd00ff;
    display: inline-flex;
    padding: 14px 30px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-decoration: none;
    color: #fff;
    &:hover {
        background-color: #bd00ff;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
        line-height: 19px;
    }
    @media (max-width: $md6+px) {
        padding: 14px 0;
        display: block;
        width: 100%;
        text-align: center;
        background-color: black;
    }
}

.btn-secondary {
    border: 1px solid #bd00ff;
    display: inline-flex;
    padding: 14px 30px;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.2s;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    background-color: #bd00ff;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        transition: all 0.5s ease;
    }
    &:hover {
        &::before {
            width: 100%;
        }
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
        line-height: 19px;
    }
    @media (max-width: $md6+px) {
        padding: 14px 30px;
        text-align: center;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.Dropdown-control {
    margin-right: 20px;
    border-radius: 0;
    background-color: transparent!important;
    border: none!important;
    text-transform: uppercase!important;
    color: #FFF!important;
    font-size: 20px!important;
    padding-right: 30px!important;
    @media(max-width: $md1+px) {
        font-size: 16px!important;
    }
}
.Dropdown-menu {
    width: 70px!important;
}



.ovf-hidden {
    overflow: hidden;
  }
  
  .preloader {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #1b1b1b;
    opacity: 1;
    transition: all 0.2s;
    &.hidePreloader {
      opacity: 0;
      pointer-events: none;
    }
  }
  
  .lds-ring {
    margin-left: calc(50% - 40px);
    margin-top: calc(50vh - 50px);
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #bd00ff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #bd00ff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  