@import '../../../styles/var';

.ourPlatform {
    margin-top: 200px;
    @media (max-width: $mw+px) {
        margin-top: 190px;
    }
    @media (max-width: $md5+px) {
        margin-top: 150px;
    }
    .header {

    }
    .text {
        margin: 40px 0;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        width: 800px;
        color: #BCBCBC;
        @media (max-width: $mw+px) {
            font-size: 16px;
            line-height: 24px;
            width: 666px;
        }
        @media (max-width: $md4+px) {
            width: 100%;
        }
        @media (max-width: $md6+px) {
            font-size: 14px;
            line-height: 21px;
        }
    }
    .icons {
        display: flex;
        gap: 40px;
        @media (max-width: $md3+px) {
            display: grid;
            grid-template: repeat(2,1fr)/repeat(2,0fr);
            gap: 20px 60px;
        }
        @media (max-width: $md6+px) {
            gap: 20px 20px;
        }
        img {
            transition: all 0.2s;
            cursor: pointer;
            @media (max-width: $md5+px) {
                height: 49px;
            }
            @media (max-width: 400px) {
                height: 40px;
            }
            &:hover {
                scale: 1.05;
            }
        }
    }
}