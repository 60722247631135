@import '../../../styles/var';

.around {
    position: relative;
    .content {
        position: relative;
        z-index: 2;
        .header {
            width: 1150px;
            @media (max-width: $md1+px) {
                width: 890px;
            }
            @media (max-width: $md3+px) {
                width: 100%;
            }
        }
        .text {
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #BCBCBC;
            width: 800px;
            margin-top: 40px;
            @media (max-width: $md1+px) {
                width: 666px;
                font-size: 16px;
                line-height: 24px;
            }
            @media (max-width: $md4+px) {
                width: 100%;
            }
            @media (max-width: $md6+px) {
                font-size: 14px;
                line-height: 21px;
            }
            .par {
                margin-top: 20px;
                @media (max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
        .second {
            margin: 20px 0 40px 0;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            @media (max-width: $md1+px) {
                font-size: 20px;
                line-height: 27px;
            }
            @media (max-width: $md6+px) {
                margin: 20px 0 30px 0;
            }
        }
        ul {
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            margin-left: 25px;
            li {
                padding: 0 5px;
                margin: 10px 0;
                list-style-image: url('../../../assets/img/Tools/star.svg');
            }
        }
    }
    img {
        position: absolute;
        z-index: 1;
        right: -200px;
        top: -100px;
        @media (max-width: $md1+px) {
            width: 800px;
            top: -50px;
            right: -100px;
        }
        @media (max-width: $md2+px) {
            width: 700px;
            top: 50px;
        }
        @media (max-width: $md3+px) {
            display: none;
        }
    }
}