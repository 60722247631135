@import '../../../styles/var';

.howToStart {
    margin-top: 180px;
    padding-bottom: 200px;
    @media (max-width: $md1+px) {
        margin-top: 120px;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 50px;
    }
    .header {

    }
    .text {
        width: 663px;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #BCBCBC;
        margin-top: 40px;
        @media(max-width: $md1+px) {
            font-size: 16px;
            line-height: 24px;
        }
        @media(max-width: $md4+px) {
            width: 100%;
        }
    }
    .content {
        margin-top: 114px;
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-right: 100px;
        @media(max-width: $md3+px) {
            display: block;
            margin-top: 40px;
            margin-right: 0;
        }
        .str {
            position: absolute;
            width: 100%;
            height: 1px;
            z-index: 1;
            top: 50px;
            background: linear-gradient(90deg, rgba(189, 0, 255, 0.2) 43.41%, rgba(189, 0, 255, 0.8) 70.33%);
            @media(max-width: $md3+px) {
                width: 1px;
                height: calc(100% - 60px);
                background: linear-gradient(180deg, rgba(189, 0, 255, 0.2) 43.41%, rgba(189, 0, 255, 0.8) 70.33%);
                left: 52px;
            }
            @media(max-width: $md6+px) {
                left: 35px;
            }
        }
        &Item {
            z-index: 2;
            font-weight: 400;
            font-size: 24px;
            line-height: 30px;
            position: relative;
            @media(max-width: $md3+px) {
                display: flex;
                margin-bottom: 60px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                line-height: normal;
                margin-bottom: 30px;
            }
            &TextTop {
                text-align: center;
                position: absolute;
                width: 320px;
                top: -50px;
                left: -108px;
                @media(max-width: $md3+px) {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: auto;
                    text-align: left;
                    top: 40px;
                    margin-left: 35px;
                }
                @media(max-width: $md6+px) {
                    margin-left: 15px;
                    top: 25px;

                }
            }
            &TextBottom {
                text-align: center;
                position: absolute;
                width: 320px;
                bottom: -125px;
                left: -108px;
                @media(max-width: $md3+px) {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: auto;
                    text-align: left;
                    top: 30px;
                }
                @media(max-width: $md6+px) {
                    top: 15px;
                    font-size: 14px;
                }
            }
        }
    }
}

.circle {
    &:hover {
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            border-radius: 100px;
            box-shadow: 0px 0px 6px 3px #BD00FF;
            position: absolute;
            left: 0;
        }
    }
    &::before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 100px;
        box-shadow: 0px 0px 6px 0px #BD00FF;
        position: absolute;
        left: 0;
        transition: all .2s;
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    text-align: center;
    width: 104px;
    height: 104px;
    border-radius: 100px;
    font-family: 'TT Lakes Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 104px;
    background-color: #1b1b1b;
    @media(max-width: $md6+px) {
        width: 70px;
        height: 70px;
        font-size: 40px;
        line-height: 75px;
    }
    &1 {
        box-shadow: inset 0px 0px 0px 3px #BD00FF;
    }
    &2 {
        box-shadow: inset 0px 0px 0px 6px #BD00FF;
    }
    &3 {
        box-shadow: inset 0px 0px 0px 12px #BD00FF;
        @media(max-width: $md6+px) {
            box-shadow: inset 0px 0px 0px 10px #BD00FF;
        }
    }
    &4 {
        box-shadow: inset 0px 0px 0px 20px #BD00FF;
        @media(max-width: $md6+px) {
            box-shadow: inset 0px 0px 0px 14px #BD00FF;
        }
    }
    &5 {
        background-color: #BD00FF;
    }
}
