@import "../../../styles/_var.scss";

.header {
    position: relative;
    z-index: 500;
}
.headerBody {
    height: 50px;
    margin-top: 50px;
}
.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerRowLogo {
    max-width: 70px;
    width: 70px;
    position: relative;
    z-index: 5;
    @media (max-width: $md6+px) {
        max-width: 60px;
        width: 60px;
    }
    img {
        width: 100%;
    }
}
.headerRowCenterMobile {
    display: none;
    @media (max-width: $md4+px) {
        display: block;
    }
    p {
        font-size: 20px;
        line-height: 1.1;
        color: #bd00ff;
        margin-bottom: 60px;
        &:last-child {
            margin-bottom: 0;
        }
        @media (max-width: $md5+px) {
            font-size: 20px;
            margin-bottom: 30px;
        }
        @media (max-height: 600px) {
            margin-bottom: 15px;
        }
        @media (max-height: 430px) {
            margin-bottom: 5px;
            font-size: 18px;
        }
    }
}
.headerRowCenterWrapper {
    @media (max-width: $md4+px) {
        position: absolute;
        z-index: 10;
        top: -50px;
        margin-top: 0px;
        right: -50px;
        pointer-events: none;
        width: 600px;
        height: 650px;

        z-index: 4;
        overflow: auto;
        display: block;
        text-align: center;
        opacity: 0;
        transition: all 0.3s linear;

        &.active {
            pointer-events: visible;
            opacity: 1;
            right: 0;
            top: -50px;
            @media (max-width: $md5+px) {
                top: 0;
            }
        }
    }
    @media (max-width: $md5+px) {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
    }
}
.headerRowCenter {
    width: 1000px;
    display: flex;
    justify-content: space-between;

    a {
        font-size: 20px;
        line-height: 1;
        color: #ffffff;
    }
    @media (max-width: $md1+px) {
        width: 780px;
        a {
            font-size: 16px;
        }
    }
    @media (max-width: $md2+px) {
        width: 700px;
    }
    @media (max-width: $md3+px) {
        width: 600px;
        a {
            font-size: 14px;
        }
    }

    @media (max-width: $md4+px) {
        display: block;
        width: 100%;
        height: 643px;
        text-align: left;
        padding: 160px 10px 10px 146px;
        background-color: #000;
        a {
            font-size: 32px;
            color: #ffffff;
            display: block;
            margin-bottom: 30px;
            @media (max-width: $md5+px) {
                margin-bottom: 40px;
                font-size: 24px;
            }
            @media (max-height: 600px) {
                margin-bottom: 20px;
                font-size: 20px;
            }
            @media (max-height: 430px) {
                margin-bottom: 5px;
                font-size: 18px;
            }
        }
    }
    @media (max-width: $md5+px) {
        height: 100%;
        padding: 160px 18px 20px 18px;
    }
}
.headerRowCenterItem {
}
.headerRowRight {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;
}
.headerRowRightLocalization {
    margin-right: 30px;
    position: relative;
}
.headerRowRightLocalizationTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-size: 20px;
    line-height: 1;
    color: #ffffff;
    @media (max-width: $md1+px) {
        font-size: 14px;
    }

    &.active {
        img {
            transform: rotate(180deg);
        }
    }
    img {
        margin-left: 5px;
    }
}
.headerRowRightLocalizationItems {
    position: absolute;
    left: 2px;
    top: 30px;
    background: #fff;
    color: #000;
    padding: 5px 0;
    p {
        padding: 5px 10px;
    }
    display: none;
    &.active {
        display: block;
    }
}
.headerRowRightLogBtn {
    width: 90px;
    height: 33px;
    background: #bd00ff;
    line-height: 33px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    position: relative;
    transition: all 0.2s linear;
    cursor: pointer;
    &:hover {
        &::before {
            width: 100%;
        }
    }
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: rgba(255, 255, 255, 0.3);
        transition: all 0.5s ease;
    }
    @media (max-width: $md1+px) {
        width: 77px;
        height: 31px;
        font-size: 14px;
        line-height: 31px;
    }
}
.headerRowRightBurger {
    position: relative;
    height: 23px;
    width: 32px;
    margin-left: 54px;
    z-index: 10;
    display: none;
    cursor: pointer;
    position: relative;
    &:after {
        content: "";
        display: "inline-block";
        left: -10px;
        top: -8px;
        position: absolute;
        width: 50px;
        height: 40px;
    }

    @media (max-width: $md4+px) {
        margin-left: 24px;
        display: block;
    }
    span {
        pointer-events: none;
        position: absolute;
        width: 32px;
        transition: all 0.3s linear;
        height: 2px;
        background-color: #fff;
        &:first-child {
            left: 0;
            top: 0;
        }
        &:nth-child(2) {
            top: 0;
            bottom: 0;
            right: 0;
            width: 20px;
            margin: auto;
        }
        &:last-child {
            bottom: 0;
            right: 0;
        }
    }
    &.active {
        span {
            &:first-child {
                left: 0;
                top: 11px;

                margin: auto;
                width: 32px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                transform: rotate(-45deg);
                width: 32px;
            }
            &:last-child {
                opacity: 0;
            }
        }
    }
}
