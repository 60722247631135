@import "../../../styles/_var.scss";
.levels {
    overflow: visible;
    position: relative;
    z-index: 30;
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 70px;
        right: 120px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        transform: scale(2.5);
        background: url("../../../assets/img/affiliates/2/bg.png") center center / contain no-repeat;
    }
}
.levelsBody {
    padding-top: 0px;
    padding-bottom: 180px;
    position: relative;
    z-index: 2;
}
.levelsTitle {
    max-width: 600px;
    margin-bottom: 60px;
}
.levelsRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md2+px) {
        width: 1352px;
        margin-bottom: 10px;
    }
    @media (max-width: $md3+px) {
        width: 1250px;
    }
    @media (max-width: $md5+px) {
        width: 920px;
    }
}
.levelsRowWrap {
    overflow: auto;
    &::-webkit-scrollbar {
        height: 5px;
        background-color: #44304b;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #bd00ff;
    }
}
.levelsRowColumn {
    width: 485px;
    height: 600px;
    padding: 239px 40px 30px 75px;
    transition: all .5s;
    &:hover {
        padding-top: 210px;
        @media(max-width: $md1+px) {
            padding-top: 160px;
        }
        @media(max-width: $md5+px) {
            padding-top: 140px;
        }
    }
    @media (max-width: $md1+px) {
        width: 400px;
        height: 394px;
        padding: 180px 40px 28px 50px;
    }
    @media (max-width: $md5+px) {
        width: 293px;
        height: 377px;
        padding: 156px 10px 28px 35px;
    }
    &:nth-child(1) {
        background: url("../../../assets/img/affiliates/2/1.png") 0 0 / cover no-repeat;
        @media (max-width: $md1+px) {
            background: url("../../../assets/img/affiliates/2/11.png") 0 0 / cover no-repeat;
        }
        @media (max-width: $md5+px) {
            background: url("../../../assets/img/affiliates/2/111.png") 0 0 / cover no-repeat;
        }
    }
    &:nth-child(2) {
        background: url("../../../assets/img/affiliates/2/2.png") 0 0 / cover no-repeat;
        @media (max-width: $md1+px) {
            background: url("../../../assets/img/affiliates/2/22.png") 0 0 / cover no-repeat;
        }
        @media (max-width: $md5+px) {
            background: url("../../../assets/img/affiliates/2/222.png") 0 0 / cover no-repeat;
        }
    }
    &:nth-child(3) {
        background: url("../../../assets/img/affiliates/2/3.png") 0 0 / cover no-repeat;
        @media (max-width: $md1+px) {
            background: url("../../../assets/img/affiliates/2/33.png") 0 0 / cover no-repeat;
        }
        @media (max-width: $md5+px) {
            background: url("../../../assets/img/affiliates/2/333.png") 0 0 / cover no-repeat;
        }
    }
}
.levelsRowColumnTitle {
    font-size: 32px;
    line-height: 1;
    color: #ffffff;
    margin-bottom: 20px;
    @media (max-width: $md5+px) {
        font-size: 20px;
    }
}
.levelsRowColumnSubtitle {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.4;
    color: #bcbcbc;
    @media (max-width: $md1+px) {
        font-size: 16px;
    }
}
