@import "../../../styles/_var.scss";

.posRel {
  position: relative;
}

.utipPlatformWrap {
  position: relative;
  max-width: 945px;
  width: 100%;
  background-image: url("../../../assets/img/Index/utip-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  padding: 80px 100px 80px 150px;
  z-index: 9999;
  @media (max-width: $md6+px) {
    padding: 40px 20px 60px 20px;
    background-size: 100% 100%;
    background-image: url("../../../assets/img/Index/utip-mobile-bg.png");
  }
  &:before {
    content: '';
    width: 205px;
    height: 105px;
    position: absolute;
    left: 12px;
    top: 65px;
    background-color: #BD00FF;
    z-index: 9;
    @media (max-width: $md6+px) {
      display: none;
    }
  }
  .utipPlatformTitle {
    font-family: "TT Lakes Medium";
    line-height: 130%;
    position: relative;
    font-size: 60px;
    line-height: 84px;
    margin-bottom: 40px;
    z-index: 99;
    @media (max-width: $md6+px) {
      font-size: 36px;
      line-height: 48px;
      padding-right: 120px;
    }
  }
  .utipPlatformText {
    font-size: 20px;
    color: #BCBCBC;
    max-width: 620px;
    margin-bottom: 40px;
    line-height: 150%;
    @media(max-width: $md6+px) {
      font-size: 14px;
    }
  }
  .utipPlatformBtnWrap {
    .utipPlatformLink {
      img {
        transition: all .2s;
        cursor: pointer;
        &:hover {
          scale: 1.05
        }
      }
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.utipPlatformLights {
  &:before {
    content: '';
    display: inline-block;
    left: 100px;
    top: -200px;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url('../../../assets/img/Index/utip-lights-bg.png');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }
  &:after {
    content: '';
    display: inline-block;
    bottom: -230px;
    right: 125px;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-image: url('../../../assets/img/Index/utip-lights-bg.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
  }
}
