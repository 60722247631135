@import "../../../styles/_var.scss";

.eagleTradeContainer {
  margin-top: 200px;
  @media (max-width: $mw+px) {
    margin-top: 180px;
  }
  @media (max-width: $md6+px) {
      margin-top: 150px;
  }
  position: relative;
  &:before {
    content: '';
    width: 205px;
    height: 105px;
    position: absolute;
    left: 0px;
    top: -30px;
    background-color: #BD00FF;
    z-index: 9;
    @media (max-width: $md6+px) {
      display: none;
    }
  }
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(50% 50% at 50% 50%, #BD00FF 0%, #7900A4 49.66%, #2004E9 100%);
    opacity: 0.5;
    filter: blur(300px);
    width: 600px;
    height: 600px;
    z-index: 9;
  }
  .eagleTradeTitle {
    font-size: 64px;
    margin-bottom: 60px;
    position: relative;
    z-index: 99;
    font-family: "TT Lakes Medium";
    line-height: 130%;
    @media (max-width: $md6+px) {
      font-size: 36px;
      margin-top: 100px;
    }
  }
  .eagleTradeRow {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 99;
    .col4 {
      flex: 0 0 auto;
      width: 33.33333333%;
      .eagleTradeItem {
        background: linear-gradient(225deg,transparent 30px,#151515 0);
        padding: 40px 60px 70px;
        margin: 0 20px;
        height: 100%;
        img {
          margin-bottom: 100px;
        }
        .eagleTradeItemTitle {
          font-size: 32px;
          margin-bottom: 20px;
        }
        .eagleTradeItemText {
          font-size: 20px;
          color: #BCBCBC;

        }
      }
    }
  }
  .eagleTradeTable {
    position: relative;
    margin-top: 100px;
    z-index: 99;

    .eagleTradeColumn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px 30px;

      &.eagleTradeColumnBlack {
        background-color: #1B1B1B;
      }
      .eagleTradeTableLabel {
        font-size: 20px;
        color: #BCBCBC;
        display: block;
        max-width: 250px;
        width: 100%;
        @media (max-width: $md6+px) {
          font-size: 14px;
        }
      }
      .eagleTradeTableText {
        font-size: 20px;
        display: block;
        max-width: 570px;
        width: 100%;
        @media (max-width: $md6+px) {
          font-size: 14px;
        }
      }
    }
    .linkWrap {
      display: flex;
      justify-content: center;
      margin-top: 55px;
      .eagleTradeLink {
        padding: 14px 30px;
        background-color: #BD00FF;
        font-size: 20px;
        color: #E7E7E7;
        border: 1px solid #BD00FF;
        transition: all .3s ease;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
